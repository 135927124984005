<template>
  <section id="report__patient">
    <v-container grid-list-md v-if="items.length">
      <v-row>
        <v-col>
          <v-card dense class="mx-auto print-landscape" max-width="70%">
            <v-card-title
              >{{ $t('summary.report.title.observance') }}

              <v-spacer></v-spacer>
              <v-btn
                @click="createObservancePDF"
                color="primary"
                v-if="!loading"
                text
              >
                <v-icon>mdi-file-pdf-outline</v-icon
                >{{ $t('summary.datatable.downloadPDF') }}</v-btn
              >
            </v-card-title>
            <v-card-text>
              {{
                $t('summary.report.text.observance', {
                  days: items.length,
                  notCompleted: notCompleted,
                  late: late,
                })
              }}

              <v-simple-table ref="observanceTable">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        {{ $t('summary.report.headers.notCompletedDays') }}
                      </th>
                      <th>
                        {{ $t('summary.report.headers.lateCompletedDays') }}
                      </th>
                      <th>
                        {{ $t('summary.report.headers.withoutLuminoDays') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredItems" :key="item.to_real_date">
                      <td>{{ item.from_real_date | date }} &#8594; {{ item.to_real_date | date }}</td>
                      <td><v-icon v-if="!item.filled">mdi-check</v-icon></td>
                      <td>{{ item.delay }}</td>
                      <td>
                        <v-icon v-if="item.no_luminotherapy">mdi-check</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="page-break-before: auto;">
        <v-col>
          <v-card dense class="mx-auto" max-width="70%">
            <v-card-title
              >{{ $t('summary.report.title.circadianRhythmAndSleep') }}
              <v-spacer></v-spacer>
              <v-btn
                @click="createCircadianRhythmPDF"
                color="primary"
                v-if="!loading"
                text
              >
                <v-icon>mdi-file-pdf-outline</v-icon
                >{{ $t('summary.datatable.downloadPDF') }}</v-btn
              >
            </v-card-title>
            <v-card-text>
              <span ref="metricsList">
                <ul>
                  <li v-for="mctq in mctq_scores" :key="`mctq-${mctq.day}`">
                    {{
                      $t('summary.report.text.mctq_scores', {
                        'day': mctq.day,
                        'chronotype': mctq.chronotype,
                      })
                    }}
                  </li>
                </ul>
                <ul>
                  <li v-for="psqi in psqi_scores" :key="`psqi-${psqi.day}`">
                    {{
                      $t('summary.report.text.psqi_scores', {
                        'day': psqi.day,
                        'psqi_score': psqi.psqi_score,
                        'psqi_text': psqi.psqi_text,
                      })
                    }}
                  </li>
                </ul>
                <ul>
                  <li v-for="isi in isi_scores" :key="`isi-${isi.day}`">
                    {{
                      $t('summary.report.text.isi_scores', {
                        'day': isi.day,
                        'isi_score': isi.isi_score,
                        'isi_text': isi.isi_text,
                      })
                    }}
                  </li>
                </ul>
                <ul>
                  <li v-for="sleep in sleep_data" :key="`sleep-${sleep.day}`">
                    {{
                      $t('summary.report.text.sleep', {
                        'day': sleep.day,
                        'sleep_debt': sleep.sleep_debt,
                        'jet_lag': sleep.jet_lag,
                      })
                    }}
                  </li>
                </ul>
              </span>

              <span v-for="item in items" :key="item.id">
                <v-card
                  class="mx-auto my-4 pa-md-4"
                  max-width="70%"
                  v-if="
                    item.raw_scores.sleep_avg_free ||
                      item.raw_scores.sleep_latency_avg_work
                  "
                  style="page-break-after: always;"
                >
                  <v-card-title>{{ item.from_real_date | date }} &#8594; {{ item.to_real_date | date }}</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{{ $t('summary.report.headers.workDays') }}</th>
                            <th>
                              {{ $t('summary.report.headers.freeDays') }}s
                            </th>
                          </tr>
                          <tr>
                            <th></th>
                            <th>
                              {{ $t('summary.report.headers.average') }} [min ;
                              max]
                            </th>
                            <th>
                              {{ $t('summary.report.headers.average') }} [min ;
                              max]
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ $t('summary.report.headers.bedtimeHour') }}
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.bedtime_avg_work &&
                                    item.raw_scores.bedtime_min_work &&
                                    item.raw_scores.bedtime_max_work
                                "
                              >
                                {{ item.raw_scores.bedtime_avg_work }} [
                                {{ item.raw_scores.bedtime_min_work }} -
                                {{ item.raw_scores.bedtime_max_work }} ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.bedtime_avg_free &&
                                    item.raw_scores.bedtime_min_free &&
                                    item.raw_scores.bedtime_max_free
                                "
                              >
                                {{ item.raw_scores.bedtime_avg_free }} [
                                {{ item.raw_scores.bedtime_min_free }} -
                                {{ item.raw_scores.bedtime_max_free }} ]
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{ $t('summary.report.headers.asleepHour') }}
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.asleep_avg_work &&
                                    item.raw_scores.asleep_min_work &&
                                    item.raw_scores.asleep_max_work
                                "
                              >
                                {{ item.raw_scores.asleep_avg_work }} [
                                {{ item.raw_scores.asleep_min_work }} -
                                {{ item.raw_scores.asleep_max_work }} ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.asleep_avg_free &&
                                    item.raw_scores.asleep_min_free &&
                                    item.raw_scores.asleep_max_free
                                "
                              >
                                {{ item.raw_scores.asleep_avg_free }} [
                                {{ item.raw_scores.asleep_min_free }} -
                                {{ item.raw_scores.asleep_max_free }} ]
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{ $t('summary.report.headers.awakeHour') }}
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.awake_avg_work &&
                                    item.raw_scores.awake_min_work &&
                                    item.raw_scores.awake_max_work
                                "
                              >
                                {{ item.raw_scores.awake_avg_work }} [
                                {{ item.raw_scores.awake_min_work }} -
                                {{ item.raw_scores.awake_max_work }} ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.awake_avg_free &&
                                    item.raw_scores.awake_min_free &&
                                    item.raw_scores.awake_max_free
                                "
                              >
                                {{ item.raw_scores.awake_avg_free }} [
                                {{ item.raw_scores.awake_min_free }} -
                                {{ item.raw_scores.awake_max_free }} ]
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{ $t('summary.report.headers.sleepLatency') }}
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.sleep_latency_avg_work &&
                                    item.raw_scores.sleep_latency_min_work &&
                                    item.raw_scores.sleep_latency_max_work
                                "
                              >
                                {{ item.raw_scores.sleep_latency_avg_work }} [
                                {{ item.raw_scores.sleep_latency_min_work }} -
                                {{ item.raw_scores.sleep_latency_max_work }} ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.sleep_latency_avg_free &&
                                    item.raw_scores.sleep_latency_min_free &&
                                    item.raw_scores.sleep_latency_max_free
                                "
                              >
                                {{ item.raw_scores.sleep_latency_avg_free }} [
                                {{ item.raw_scores.sleep_latency_min_free }} -
                                {{ item.raw_scores.sleep_latency_max_free }} ]
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t('summary.report.headers.waso') }}</td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.WASO_avg_work &&
                                    item.raw_scores.WASO_min_work &&
                                    item.raw_scores.WASO_max_work
                                "
                              >
                                {{ item.raw_scores.WASO_avg_work }} [
                                {{ item.raw_scores.WASO_min_work }} -
                                {{ item.raw_scores.WASO_max_work }} ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.WASO_avg_free &&
                                    item.raw_scores.WASO_min_free &&
                                    item.raw_scores.WASO_max_free
                                "
                              >
                                {{ item.raw_scores.WASO_avg_free }} [
                                {{ item.raw_scores.WASO_min_free }} -
                                {{ item.raw_scores.WASO_max_free }} ]
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{
                                $t('summary.report.headers.totalSleepDuration')
                              }}
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.sleep_duration_avg_work &&
                                    item.raw_scores.sleep_duration_min_work &&
                                    item.raw_scores.sleep_duration_max_work
                                "
                              >
                                {{ item.raw_scores.sleep_duration_avg_work }} [
                                {{ item.raw_scores.sleep_duration_min_work }} -
                                {{ item.raw_scores.sleep_duration_max_work }} ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.sleep_duration_avg_free &&
                                    item.raw_scores.sleep_duration_min_free &&
                                    item.raw_scores.sleep_duration_max_free
                                "
                              >
                                {{ item.raw_scores.sleep_duration_avg_free }} [
                                {{ item.raw_scores.sleep_duration_min_free }} -
                                {{ item.raw_scores.sleep_duration_max_free }} ]
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {{ $t('summary.report.headers.sleepEfficiency') }}
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.sleep_efficiency_avg_work &&
                                    item.raw_scores.sleep_efficiency_min_work &&
                                    item.raw_scores.sleep_efficiency_max_work
                                "
                              >
                                {{ item.raw_scores.sleep_efficiency_avg_work }}
                                [
                                {{ item.raw_scores.sleep_efficiency_min_work }}
                                -
                                {{ item.raw_scores.sleep_efficiency_max_work }}
                                ]
                              </span>
                            </td>
                            <td>
                              <span
                                v-if="
                                  item.raw_scores.sleep_efficiency_avg_free &&
                                    item.raw_scores.sleep_efficiency_min_free &&
                                    item.raw_scores.sleep_efficiency_max_free
                                "
                              >
                                {{ item.raw_scores.sleep_efficiency_avg_free }}
                                [
                                {{ item.raw_scores.sleep_efficiency_min_free }}
                                -
                                {{ item.raw_scores.sleep_efficiency_max_free }}
                                ]
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              {{ $t('summary.report.noData') }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import { mapGetters } from 'vuex'

export default {
  name: 'InsoluxReport',

  props: {
    errorMessage: {
      required: false,
      type: String,
      default: '',
    },
    items: {
      required: true,
      type: Array,
    },
    scores: {
      required: true,
      type: Array,
    },
    loading: {
      required: true,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(['user']),
    filteredItems() {
      let cleaned_items = []
      this.items.forEach(item => {
        if (
          item.delay > 0 ||
          item.no_luminotherapy === true ||
          item.filled === false
        )
          cleaned_items.push(item)
      })

      return cleaned_items
    },

    notCompleted() {
      let notCompleted = 0
      this.items.forEach(item => {
        if (item.filled === false) notCompleted++
      })

      return notCompleted
    },

    late() {
      let late = 0
      this.items.forEach(item => {
        if (item.delay > 0) late++
      })

      return late
    },

    isi_scores() {
      let isi_scores = []
      this.scores.forEach(score => {
        if (score.INS_ISI) {
          isi_scores.push(score.INS_ISI)
        }
      })

      return isi_scores
    },

    mctq_scores() {
      let mctq_scores = []
      this.scores.forEach(score => {
        if (score.INS_MCTQ) {
          mctq_scores.push(score.INS_MCTQ)
        }
      })

      return mctq_scores
    },

    psqi_scores() {
      let psqi_scores = []
      this.scores.forEach(score => {
        if (score.INS_PSQI) {
          psqi_scores.push(score.INS_PSQI)
        }
      })

      return psqi_scores
    },

    sleep_data() {
      let sleep_data = []
      this.items.forEach(item => {
        if (item.raw_scores['V21'] && item.raw_scores['V22']) {
          sleep_data.push({
            'day': item.day,
            'sleep_debt': item.raw_scores['V21'],
            'jet_lag': item.raw_scores['V22'],
          })
        }
      })

      return sleep_data
    },
  },

  filters: {
    dateDay: function(str) {
      if (!str) return ''
      const day = new Date(str).toLocaleDateString(
        navigator.language || 'fr-FR',
        {
          weekday: 'long',
        }
      )
      return day[0].toUpperCase() + day.slice(1)
    },
    date: function(str) {
      if (!str) return ''
      return new Date(str).toLocaleDateString(navigator.language || 'fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
    hour: function(str) {
      if (!str) return ''
      return new Date(str).toLocaleDateString(navigator.language || 'fr-FR', {
        hour: '2-digit',
        minute: '2-digit',
      })
    },
  },
  methods: {
    createObservancePDF() {
      let rows = []
      let columnHeader = [
        this.$t('summary.report.headers.notCompletedDays'),
        this.$t('summary.report.headers.lateCompletedDays'),
        this.$t('summary.report.headers.withoutLuminoDays'),
      ]
      let pdfName = `${this.$t('summary.report.title.observance')}_${
        this.user.username
      }`

      this.items.forEach(element => {
        var tmp = [
          this.$options.filters.date(element.from_real_date) + " > " + this.$options.filters.date(element.to_real_date),
          !element.filled ? 'X' : '',
          element.delay,
          element.no_luminotherapy ? 'X' : '',
        ]

        rows.push(tmp)
      })

      const doc = new jsPDF()

      // pdf properties
      doc.setProperties({
        title: `${this.$t('summary.report.title.observance')} : ${
          this.user.username
        }`,
        subject: 'Oniros',
        author: 'Oniros Application',
      })

      const logoSrc = require('@/assets/img/logo-oniros.png')
      let logo = new Image()
      logo.src = logoSrc

      doc.addImage(logo, 'PNG', 5, 5, 28, 5)

      doc.text(
        20,
        20,
        `${this.$t('summary.report.title.observance')} : ${this.user.username}`
      )

      doc.setFontSize(8)

      doc.text(
        20,
        30,
        this.$t('summary.report.text.observance', {
          days: this.items.length,
          notCompleted: this.notCompleted,
          late: this.late,
        })
      )

      doc.autoTable({
        columns: columnHeader,
        body: rows,
        startY: 40,
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        headStyles: {
          fillColor: [241, 196, 15],
          fontSize: 6,
        },
        columnStyles: { text: { cellWidth: 'auto' } },
      })

      const pages = doc.internal.getNumberOfPages()
      const pageWidth = doc.internal.pageSize.width
      const pageHeight = doc.internal.pageSize.height

      doc.setFontSize(7)

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2
        let verticalPos = pageHeight - 10
        let today = new Date().toLocaleDateString()
        doc.setPage(j)
        // TODO: check why align param not working for now
        doc.text(
          `Oniros - ${today} - page ${j} / ${pages}`,
          horizontalPos,
          verticalPos,
          'right'
        )
      }

      doc.save(pdfName + '.pdf')
    },
    createCircadianRhythmPDF() {
      let rows = []
      let rows2 = []
      let columnHeader = ['']
      let columnHeader2 = [
        'date',
        '',
        `${this.$t('summary.report.headers.workDays')} (${this.$t(
          'summary.report.headers.average'
        )} [min ; max])`,
        `${this.$t('summary.report.headers.workDays')} (${this.$t(
          'summary.report.headers.average'
        )} [min ; max])`,
      ]

      let pdfName = `${this.$t('summary.title.circadianRhythmFilename')}_${
        this.user.username
      }`

      // parse mctq
      this.mctq_scores.forEach(mctq => {
        var tmp = [
          this.$t('summary.report.text.mctq_scores', {
            'day': mctq.day,
            'chronotype': mctq.chronotype,
          }),
        ]

        rows.push(tmp)
      })
      // parse psqi
      this.psqi_scores.forEach(psqi => {
        var tmp = [
          this.$t('summary.report.text.psqi_scores', {
            'day': psqi.day,
            'psqi_score': psqi.psqi_score,
            'psqi_text': psqi.psqi_text,
          }),
        ]

        rows.push(tmp)
      })
      // parse isi
      this.isi_scores.forEach(isi => {
        var tmp = [
          this.$t('summary.report.text.isi_scores', {
            'day': isi.day,
            'isi_score': isi.isi_score,
            'isi_text': isi.isi_text,
          }),
        ]

        rows.push(tmp)
      })
      // parse sleep data
      this.sleep_data.forEach(sleep => {
        var tmp = [
          this.$t('summary.report.text.sleep', {
            'day': sleep.day,
            'sleep_debt': sleep.sleep_debt,
            'jet_lag': sleep.jet_lag,
          }),
        ]

        rows.push(tmp)
      })

      // rest of data
      this.items.forEach(item => {
        if (
          item.raw_scores.sleep_avg_free ||
          item.raw_scores.sleep_latency_avg_work
        ) {
          let realDate = this.$options.filters.date(item.from_real_date) + " > " + this.$options.filters.date(item.to_real_date)

          rows2.push([
            realDate,
            this.$t('summary.report.headers.bedtimeHour'),
            item.raw_scores.bedtime_avg_work &&
            item.raw_scores.bedtime_min_work &&
            item.raw_scores.bedtime_max_work
              ? `${item.raw_scores.bedtime_avg_work} [ ${item.raw_scores.bedtime_min_work} - ${item.raw_scores.bedtime_max_work} ]`
              : '',
            item.raw_scores.bedtime_avg_free &&
            item.raw_scores.bedtime_min_free &&
            item.raw_scores.bedtime_max_free
              ? `${item.raw_scores.bedtime_avg_free} [ ${item.raw_scores.bedtime_min_free} - ${item.raw_scores.bedtime_max_free} ]`
              : '',
          ])
          rows2.push([
            realDate,
            this.$t('summary.report.headers.asleepHour'),
            item.raw_scores.asleep_avg_work &&
            item.raw_scores.asleep_min_work &&
            item.raw_scores.asleep_max_work
              ? `${item.raw_scores.asleep_avg_work} [ ${item.raw_scores.asleep_min_work} - ${item.raw_scores.asleep_max_work} ]`
              : '',
            item.raw_scores.asleep_avg_free &&
            item.raw_scores.asleep_min_free &&
            item.raw_scores.asleep_max_free
              ? `${item.raw_scores.asleep_avg_free} [ ${item.raw_scores.asleep_min_free} - ${item.raw_scores.asleep_max_free} ]`
              : '',
          ])
          rows2.push([
            realDate,
            this.$t('summary.report.headers.awakeHour'),
            item.raw_scores.awake_avg_work &&
            item.raw_scores.awake_min_work &&
            item.raw_scores.awake_max_work
              ? `${item.raw_scores.awake_avg_work} [ ${item.raw_scores.awake_min_work} - ${item.raw_scores.awake_max_work} ]`
              : '',
            item.raw_scores.awake_avg_free &&
            item.raw_scores.awake_min_free &&
            item.raw_scores.awake_max_free
              ? `${item.raw_scores.awake_avg_free} [ ${item.raw_scores.awake_min_free} - ${item.raw_scores.awake_max_free} ]`
              : '',
          ])
          rows2.push([
            realDate,
            this.$t('summary.report.headers.sleepLatency'),
            item.raw_scores.sleep_latency_avg_work &&
            item.raw_scores.sleep_latency_min_work &&
            item.raw_scores.sleep_latency_max_work
              ? `${item.raw_scores.sleep_latency_avg_work} [ ${item.raw_scores.sleep_latency_min_work} - ${item.raw_scores.sleep_latency_max_work} ]`
              : '',
            item.raw_scores.sleep_latency_avg_free &&
            item.raw_scores.sleep_latency_min_free &&
            item.raw_scores.sleep_latency_max_free
              ? `${item.raw_scores.sleep_latency_avg_free} [ ${item.raw_scores.sleep_latency_min_free} - ${item.raw_scores.sleep_latency_max_free} ]`
              : '',
          ])
          rows2.push([
            realDate,
            this.$t('summary.report.headers.waso'),
            item.raw_scores.WASO_avg_work &&
            item.raw_scores.WASO_min_work &&
            item.raw_scores.WASO_max_work
              ? `${item.raw_scores.WASO_avg_work} [ ${item.raw_scores.WASO_min_work} - ${item.raw_scores.WASO_max_work} ]`
              : '',
            item.raw_scores.WASO_avg_free &&
            item.raw_scores.WASO_min_free &&
            item.raw_scores.WASO_max_free
              ? `${item.raw_scores.WASO_avg_free} [ ${item.raw_scores.WASO_min_free} - ${item.raw_scores.WASO_max_free} ]`
              : '',
          ])
          rows2.push([
            realDate,
            this.$t('summary.report.headers.totalSleepDuration'),
            item.raw_scores.sleep_duration_avg_work &&
            item.raw_scores.sleep_duration_min_work &&
            item.raw_scores.sleep_duration_max_work
              ? `${item.raw_scores.sleep_duration_avg_work} [ ${item.raw_scores.sleep_duration_min_work} - ${item.raw_scores.sleep_duration_max_work} ]`
              : '',
            item.raw_scores.sleep_duration_avg_free &&
            item.raw_scores.sleep_duration_min_free &&
            item.raw_scores.sleep_duration_max_free
              ? `${item.raw_scores.sleep_duration_avg_free} [ ${item.raw_scores.sleep_duration_min_free} - ${item.raw_scores.sleep_duration_max_free} ]`
              : '',
          ])
          rows2.push([
            realDate,
            this.$t('summary.report.headers.sleepEfficiency'),
            item.raw_scores.sleep_efficiency_avg_work &&
            item.raw_scores.sleep_efficiency_min_work &&
            item.raw_scores.sleep_efficiency_max_work
              ? `${item.raw_scores.sleep_efficiency_avg_work} [ ${item.raw_scores.sleep_efficiency_min_work} - ${item.raw_scores.sleep_efficiency_max_work} ]`
              : '',
            item.raw_scores.sleep_efficiency_avg_free &&
            item.raw_scores.sleep_efficiency_min_free &&
            item.raw_scores.sleep_efficiency_max_free
              ? `${item.raw_scores.sleep_efficiency_avg_free} [ ${item.raw_scores.sleep_efficiency_min_free} - ${item.raw_scores.sleep_efficiency_max_free} ]`
              : '',
          ])
        }
      })

      const doc = new jsPDF('landscape')

      // pdf properties
      doc.setProperties({
        title: `${this.$t('summary.report.title.circadianRhythmAndSleep')} : ${
          this.user.username
        }`,
        subject: 'Oniros',
        author: 'Oniros Application',
      })

      const logoSrc = require('@/assets/img/logo-oniros.png')
      let logo = new Image()
      logo.src = logoSrc

      doc.addImage(logo, 'PNG', 5, 5, 28, 5)

      doc.text(
        20,
        20,
        `${this.$t('summary.report.title.circadianRhythmAndSleep')} : ${
          this.user.username
        }`
      )

      doc.autoTable({
        columns: columnHeader,
        body: rows,
        startY: 30,
        showHead: false,
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        headStyles: {
          fillColor: [222, 121, 29],
          fontSize: 6,
        },
        columnStyles: { text: { cellWidth: 'auto' } },
      })

      let finalY = doc.lastAutoTable.finalY || 10

      doc.autoTable({
        columns: columnHeader2,
        body: rows2,
        startY: finalY + 10,
        styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        headStyles: {
          fillColor: [222, 121, 29],
          fontSize: 6,
        },
        columnStyles: { text: { cellWidth: 'auto' } },
      })

      const pages = doc.internal.getNumberOfPages()
      const pageWidth = doc.internal.pageSize.width
      const pageHeight = doc.internal.pageSize.height

      doc.setFontSize(7)

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2
        let verticalPos = pageHeight - 10
        let today = new Date().toLocaleDateString()
        doc.setPage(j)
        // TODO: check why align param not working for now
        doc.text(
          `Oniros - ${today} - page ${j} / ${pages}`,
          horizontalPos,
          verticalPos,
          'right'
        )
      }

      doc.save(pdfName + '.pdf')
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
